<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="100px" size="mini">
            <el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="name"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model.trim="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号" prop="idCard" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },
    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号格式不正确', trigger: 'blur' }]">
                            <el-input v-model.trim="form.idCard" @blur="readAge"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-radio-group v-model="form.sex">
                                <el-radio label="1">男</el-radio>
                                <el-radio label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="年龄" prop="age" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model.trim="form.age" type="number"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="单位" prop="companyName" v-if="!form.id"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <cascaderCompanyName v-model="form.companyName" ></cascaderCompanyName>
                        </el-form-item>
                    </el-col>
                    <el-col :span="!form.id?12:24">
                        <el-form-item label="工作区" prop="wpName" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-select v-model="form.wpName" placeholder="请选择" style="width:100%" clearable filterable>
                                    <el-option :label="item.name" :value="item.name" v-for="(item,index) in gzqList" :key="index"></el-option>
                                </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="联系电话" prop="mobile"
                                      :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model.trim="form.mobile"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>
            <el-form-item style="padding-top: 60px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import {dwList, grList, pgList} from "../../../../data/utlisData";

    export default {
        props: {
            visible: {
                default: false
            },
            form: {
                default: {
                }
            },
            gzqList: {
                default: []
            },
            title: {
                default: "添加/修改"
            },
        },
        data() {
            return {
                dwList: dwList,
                grList: grList,
                pgList: pgList,
            }
        },
        methods: {
            readAge() {
                //获取年龄
                if (this.form.idCard.length != 18) return
                const currentYear = new Date().getFullYear();
                const birthYear = parseInt(this.form.idCard.substr(6, 4), 10);
                this.form.age = currentYear - birthYear // + 1;
                this.form.sex = this.form.idCard.slice(14, 17) % 2 ? "1" : "2" == '1'
            },
            async onSubmit() {
                var va = false
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        va = true
                    }
                })
                if (!va) {
                    return
                }
                try {
                    let url = `/company/user`
                    const resp = await this.$http.post(url, this.form)
                    this.$message[resp.data.status](resp.data.msg)
                } catch (e) {
                    this.$message.error(e.message)
                }
                this.$emit('update')
            },
            handleClose() {
                this.$emit('close')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .dialog ::v-deep .el-dialog__body {
        padding: 10px 20px;
    }

    .tijian {
        border: 1px solid #c0c4cc;
        padding: 5px;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;

        .title-text {
            text-align: center;
            border-bottom: 1px solid #EBEEF5;
            border-top: 1px solid #EBEEF5;
            padding: 10px;
        }
    }

    ::v-deep .el-table .el-table__cell {
        padding: 6px 0;
    }

    .flex {
        display: flex;
    }

    .btn {
        justify-content: space-between;
        padding: 10px 0;
    }

    .border-bottom {
        border-bottom: 1px solid #EBEEF5;
    }

    .text-center {
        text-align: center;
        padding: 5px 0;
    }

    .align-items {
        align-items: center;
    }

    .text-group {
        display: grid;
        padding-right: 10px
    }
</style>
