<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handleDownload" icon="el-icon-download" type="defalut" v-has="'batch_import_download'">导出</el-button>
                <el-button size="mini" @click="handleReport" icon="el-icon-upload" type="defalut" v-has="'batch_import_person'">导入</el-button>
                <el-button size="mini" @click="handleAdd" icon="el-icon-plus" type="defalut" v-has="'add_person'">新建</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="人员管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="70px">
                <el-form-item label="搜索" label-width="60px">
                    <el-input placeholder="请输入内容" v-model="search.value" class="input-with-select" style="width:280px"
                              clearable>
                        <el-select v-model="search.key" slot="prepend" placeholder="请选择" style="width:90px">
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="身份证" value="idCard"></el-option>
                            <el-option label="手机号" value="mobile"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="建档状态">
                    <el-select v-model="search.healthCreateStatus" placeholder="请选择" style="width:150px" clearable>
                        <el-option label="全部" value="全部"></el-option>
                        <el-option label="未建档" value="0"></el-option>
                        <el-option label="已建档" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属单位类别" label-width="100px">
                    <el-select v-model="search.levelType" placeholder="请选择" style="width:150px" clearable>
                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in levelList"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人员类型">
                    <el-select v-model="search.type" placeholder="请选择" style="width:130px" clearable>
                        <el-option label="劳务输出" value="劳务输出"></el-option>
                        <el-option label="工作调转" value="工作调转"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'inventory_check'">查询</el-button>
                    <el-button type="" plain @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-row :gutter="20" style="height: 100%;display: flex;">
            <el-col :span="4">
                <div style="padding: 10px">
                    <el-input size="mini" placeholder="输入关键字进行过滤" v-model="filterText">
                    </el-input>
                </div>
                <el-tree v-loading="loading" class="filter-tree" highlight-current
                         @node-click="handleNodeClick" :filter-node-method="filterNode" :data="companyList"
                         node-key="id"   :default-expanded-keys="[1]"
                         :props="{ checkStrictly: true, label: 'name', value: 'id', emitPath: false }" ref="tree">
                </el-tree>
            </el-col>
            <el-col :span="20" class="page">
                <div class="main-content">
                    <div class="table-content">
                        <el-table :data="table.data" stripe size="mini" border v-loading="loading" width="100%"
                                  height="100%">
                            <el-table-column type="index" label="序号" align="center"
                                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                                             width="80"/>
                            <el-table-column align="center" label="基本信息" width="160">
                                <template slot-scope="scope">
                                    <div>
                                        <span>{{ scope.row.name }}</span>
                                        <span v-if="scope.row.sex == '2'"> 女 </span>
                                        <span v-if="scope.row.sex == '1'"> 男 </span>
                                        <span v-if="scope.row.age">{{ scope.row.age }} 岁</span>
                                    </div>
                                    <div>
                                        <small> {{ isFullIdCard[scope.$index] ? scope.row.idCard :
                                            maskIdCardRegex(scope.row.idCard) }}</small>
                                        <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                                           @click="toggleIdCard(scope.$index)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="联系方式" width="180">
                                <template slot-scope="scope">
                                    <div>
                                        <span v-if="scope.row.entOrgNameTwo">{{scope.row.entOrgNameTwo}}</span>
                                        <span v-if="scope.row.entOrgNameThree"> | {{scope.row.entOrgNameThree}}</span>
                                        <span v-if="scope.row.entOrgNameFour"> | {{scope.row.entOrgNameFour}}</span>
                                        <span v-if="scope.row.entOrgNameFive"> | {{scope.row.entOrgNameFive}}</span>
                                    </div>
                                    <div>{{ scope.row.mobile }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="工作区" width="180">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.wpName }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="建档状态" width="140" prop="healthCreateStatus">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.healthCreateStatus == '1'" type="success">已建档</el-tag>
                                    <el-tag v-else type="info">未建档</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="用户类型">
                                <template slot-scope="scope">
                                    <div>{{scope.row.userType}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="状态">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.is_delete">删减人员</div>
                                    <div v-else></div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="创建时间" width="140">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.created_at | datetime }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="160" label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="mini" @click="handleRowChange(scope.row)"
                                               v-has="'inventory_edit'">修改
                                    </el-button>
                                    <el-button type="text" size="mini" @click="handleDelete(scope.row)"
                                               v-has="'inventory_delete'">删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="pager">
                    <el-pagination layout="total, prev, pager, next, sizes"
                                   :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]" :current-page="table.page"
                                   :total="table.total" :page-size="table.pageSize" @current-change="handlePageChange"
                                   @size-change="handlePageSizeChange"/>
                </div>
            </el-col>
        </el-row>

        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" @update="getData"
                   :gzqList="gzqList"></data-form>
        <el-dialog
                :visible.sync="dialogVisibleExport"
                title="提示"
                width="30%"
        >
            <p>导出的数据不能超过2万条，请缩小查询范围或导出当前范围内的前2万条数据。</p>
            <p>导出时请不要关闭页面。</p>
            <el-progress :percentage="progress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleExport = false" :disabled="but_disabled" v-loading="but_loading">取消</el-button>
                <el-button type="primary" @click="exportLimitedData" :disabled="but_disabled" v-loading="but_loading">导 出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../../data/formatter"
    import {dwList, grList, pgList} from "../../../data/utlisData"
    import dataForm from "./widgets/dataform";

    const makeInitSearch = () => {
        return {
            companyName: "",
            wpName: "",
            key: "name",
            value: "",
            createdat: [],
            levelType: ""
        }
    }

    export default {
        name: 'account',
        components: {
            dataForm
        },
        data() {
            return {
                but_loading: false,
                but_disabled: false,
                levelList: [
                    // { name: "一级单位", id: 1 },
                    {name: "二级单位", id: 2},
                    {name: "三级单位", id: 3},
                    {name: "四级单位", id: 4},
                    {name: "五级单位", id: 5},
                ],
                transferVisible: false,
                dialogVisibleExport: false,
                progress: 0, // 进度条的百分比
                gzqList: [],
                data: {},
                isFullIdCard: {},
                form: {
                    age: "",
                    sex: ""
                },
                dialogVisible: false,
                formatter: formatter,
                dwList: dwList,
                grList: grList,
                pgList: pgList,
                loading: false,
                search: makeInitSearch(),
                table: data.table,
                companyList: [],
                filterText: null,
                idList: []
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        methods: {
            async handleDownload() {
                this.progress = 0; // 初始化进度
                this.dialogVisibleExport = true; // 显示进度条对话框
                // 模拟导出数据的过程
            },
            async exportLimitedData() {
                try {
                    this.but_loading = true
                    this.but_disabled = true
                    this.$http({
                        url: `/company/user/export`,
                        method: 'POST',
                        responseType: 'blob', // 重要：确保响应类型为blob
                        data: this.search,
                        headers: {},
                        onDownloadProgress:(progressEvent)=>{
                            let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                            //接收进度为99%的时候需要留一点前端编译的时间
                            if(progressBar >= 99){
                                this.progress = 99;
                            }else{
                                this.progress = progressBar;
                            }
                        }
                    }).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        let fileName = 'default.xlsx';
                        const contentDisposition = response.headers['content-disposition'];
                        if (contentDisposition) {
                            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                            if (fileNameMatch && fileNameMatch.length === 2) {
                                fileName = decodeURIComponent(fileNameMatch[1]);
                            }
                        }
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url); // 清理
                        this.but_loading = false
                        this.but_disabled = false
                        this.dialogVisibleExport = false; // 隐藏导出进度对话框
                        this.$message.success('数据导出完成！');
                    }).catch((error) => {
                        this.but_loading = false
                        this.but_disabled = false
                        this.$message({
                            message: error,
                            type: 'error'
                        });
                    });
                } catch (error) {
                    this.but_loading = false
                    this.but_disabled = false
                    this.$message({
                        message: error,
                        type: 'error'
                    });
                }
            },
            handleReport() {
                this.$message.info("还未上线！")
            },
            handleNodeClick(data) {
                this.search.companyName = data.name
                this.search.companyId = data.id
                this.search.companyPid = data.pid
                this.search.companyLevel = data.level
                this.getData()
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            maskIdCardRegex(idCard) {
                if (!idCard) return;
                return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                    return p1 + p2.replace(/./g, '*') + p3;
                });
            },
            toggleIdCard(index) {
                this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/audit/data/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handleAdd() {
                this.form = {
                    age: "",
                    sex: "",
                    companyName: ""
                }
                this.dialogVisible = true
            },
            handleRowChange(row) {
                this.form = row
                console.log(row)
                this.dialogVisible = true
            },
            reset() {
                this.search = makeInitSearch()
                setTimeout(() => {
                    this.getData()
                }, 0);
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.transferVisible = false
                this.loading = true
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                const resp = await this.$http.post(`/company/user/list`, this.search)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
            async getUserOwn() {
                const resp = await this.$http.get(`/userown?page=${this.table.page}&pageSize=500`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.gzqList = resp.data.data
                }
            },
            async getDataCompany() {
                this.$http.get(`/company/company/tree`)
                    .then(resp => {
                        this.companyList = resp.data.data
                    }).catch(err => {
                    this.$message.error(err.message)
                })
            },
        },
        mounted() {
            this.getDataCompany()
            this.getUserOwn();
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }

    .filter-tree {
        height: calc(100vh - 260px);
        overflow: auto;
    }
</style>
